import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import Checkout from "./Checkout";
function Main() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={App} />
        <Route path="/checkout" Component={Checkout} />
      </Routes>
    </Router>
  );
}

export default Main;
