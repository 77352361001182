import React from "react";
import {
  addProduct,
  allProduct,
} from "../../../recoilAtomSelector/atomSelector";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";

const CardsCheckout = ({ card }: { card: AddCards }) => {
  const [product, setProduct] = useRecoilState(addProduct);
  const refresh = useRecoilValue(allProduct);

  const cardAction = (cardValue: AddCards, action: string) => {
    // console.log(action);
    let exitCards: Array<AddCards> = product.slice();
    let exist: AddCards = exitCards.find(
      (x) => x["idCard"] == cardValue.idCard
    );
    let index = product.findIndex((x) => x["idCard"] == cardValue.idCard);
    let finalResult = [];
    let copyProduct = product.slice();
    let create: AddCards = {
      idCard: cardValue.idCard,
      name: cardValue.name,
      description: cardValue.description,
      img: cardValue.defaultImg,
      qte: cardValue.qte,
      amount: cardValue.price,
      price: cardValue.price,
      defaultImg: cardValue.defaultImg,
      alt: cardValue.alt,
      imgResponsive: cardValue.imgResponsive,
    };
    switch (action) {
      case "update": {
        if (exist.qte == 1) {
          finalResult = product.filter((x) => x["idCard"] != cardValue.idCard);
        } else {
          create.qte = exist.qte - 1;
          create.amount = create.qte * exist.price;

          //

          //product.slice(0, value);
          //let copyProduct = product.slice();
          //console.log(copyProduct);
          copyProduct.splice(index, 1, create);
          finalResult = copyProduct;
        }
        break;
      }

      case "delete": {
        finalResult = product.filter((x) => x["idCard"] != cardValue.idCard);
        break;
      }

      default: {
        create.qte = exist.qte + 1;
        create.amount = create.qte * exist.price;

        //

        //product.slice(0, value);
        //console.log(copyProduct);
        copyProduct.splice(index, 1, create);
        finalResult = copyProduct;
        break;
      }
    }
    //console.log(finalResult);
    setProduct(finalResult);
    //const allRefreshProduct = useRecoilValue(allProduct);
    let test = refresh.total_amount;
  };
  return (
    <div
      className="uk-card uk-card-default uk-grid-collapse uk-child-width-1-3@m uk-margin"
      uk-grid="true"
    >
      <div className="uk-card-media-left uk-cover-container">
        <img src={card.defaultImg} alt={card.alt} uk-cover="true" />
        {/*<canvas width="600" height="400"></canvas>*/}
      </div>
      <div>
        <div className="uk-card-body">
          <h5 className="uk-card-title">{card.name}</h5>
          <p>{card.description}</p>
          <p>
            <strong>Qt&eacute;: {card.qte}</strong>
            <strong>&nbsp; &nbsp; Prix: {card.amount}&euro;</strong>
            <span>&nbsp; &nbsp;(Prix unitaire: {card.price}&euro;) </span>
          </p>
          <div className="uk-margin-large-top">
            <button
              className="uk-button uk-button-text "
              uk-tooltip="title: Ajouter une carte"
              onClick={() => {
                cardAction(card, "add");
              }}
            >
              ajouter
            </button>
            <button
              className="uk-button uk-button-text uk-margin-small-left"
              uk-tooltip="title: Enlèver une carte"
              onClick={() => {
                cardAction(card, "update");
              }}
            >
              enlever
            </button>
            <button
              className="uk-button uk-button-text uk-margin-small-left"
              uk-tooltip="title: Enlèver cette carte du panier"
              onClick={() => {
                cardAction(card, "delete");
              }}
            >
              supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsCheckout;
