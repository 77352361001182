import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  addProduct,
  filterList,
} from "../../../recoilAtomSelector/atomSelector";
import { Bounce, ToastContainer, toast } from "react-toastify";
/*
const getListCards = [];

const addCards = (e: any) => {};

const Cards = (value: string) => {
  const [index, setIndex] = useState(1);
  return (
    <>
      <div>
        <div className="uk-card uk-card-default uk-width-1-2 uk-card-hover">
          <div className="uk-card-media-top">
            <img
              src="https://picsum.photos/200/300"
              alt=""
              className="uk-height-medium uk-width-medium uk-responsive-height uk-responsive-width"
            />
          </div>
          <div className="uk-card-body uk-padding-small">
            <p className="font-medium text-lg mb-0 pb-2">Carte: test#{index}</p>

            <div className="uk-flex uk-flex-right">
              <button
                className="uk-button uk-button-default"
                onClick={() => {
                  addCards(index);
                }}
              >
                {" "}
                add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
*/

const Card = ({ cards, height }: { cards: Cards; height: number }) => {
  const [product, setProduct] = useRecoilState(addProduct);
  //console.log("cards: ", cards);

  const addCard = (card: Cards) => {
    // console.log("element cards: ", card);
    let exist: AddCards = product.find((x) => x["idCard"] == card.idCard);
    let index = product.findIndex((x) => x["idCard"] == card.idCard);
    let create: AddCards = {
      idCard: card.idCard,
      name: card.name,
      description: card.description,
      img: card.defaultImg,
      qte: 1,
      amount: card.price,
      price: card.price,
      defaultImg: card.defaultImg,
      alt: card.alt,
      imgResponsive: card.imgResponsive,
    };

    let finalResult = [];
    if (index != -1) {
      create.qte = exist.qte + 1;
      create.amount = create.qte * create.price;
      //product.slice(0, value);
      let copyProduct = product.slice();
      //console.log(copyProduct);
      copyProduct.splice(index, 1, create);
      finalResult = copyProduct;
      //console.log("Ajout", finalResult);
    } else {
      finalResult = [...product, create];
      //console.log("Ajout else", finalResult);
    }
    // setProduct((prevState) =>  [...prevState, create]);
    setProduct(finalResult);
    toast.success(`La carte \"${card.name}\" à été ajouté.`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

    //console.log(product);
  };

  return (
    <>
      <div>
        <div className="uk-card uk-card-default uk-card-hover uk-width-auto">
          <div className="uk-card-media-top" style={{}}>
            <img
              src={cards.defaultImg}
              alt={cards.alt}
              style={{ width: "100%" }}
              className=""
            />
          </div>
          <div className="uk-card-body uk-padding-small">
            <strong className="font-medium text-lg mb-0 pb-2">
              {cards.name}
            </strong>
            <p className="">Description: {cards.description}</p>
            {/*<p>Prix: {cards.price} &euro;</p>
            <div className="uk-flex uk-flex-right">
              <button
                className="uk-button uk-button-default"
                onClick={() => {
                  addCard(cards);
                }}
              >
                ajouter
              </button> 
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

const CardsManagement = () => {
  const [array, setArray] = useState([90, 100, 75, 80]);
  const getCards = useRecoilValue(filterList);
  return (
    <>
      {getCards.map((cards, index) => {
        return (
          <div
            className="uk-margin-medium-top uk-margin-medium-bottom uk-child-width-1-2@s uk-child-width-1-4@m"
            uk-grid="masonry: pack"
            key={index}
          >
            {cards.list_images.map((element, index) => {
              const random = Math.floor(Math.random() * array.length);
              //console.log(random, element);
              return (
                <Card key={index} cards={element} height={array[random]} />
              );
            })}
          </div>
        );
      })}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="light"
        transition={Bounce}
      />
    </>
  );
};

export default CardsManagement;
