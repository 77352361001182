import React from "react";

const Loading = () => {
  const styles = {
    border: "1px solid",
    backgroundColor: "red",
  };
  return (
    <div className="" style={styles}>
      {" "}
      <p>Loading ...</p>
    </div>
  );
};

export default Loading;
