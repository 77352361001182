import React from "react";
import App from "./component/App";
import "uikit/dist/css/uikit.min.css";
import { createRoot } from "react-dom/client";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import Main from "./component/Main";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <RecoilRoot>
    <React.StrictMode>
      <Main />
    </React.StrictMode>
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
