import ModalText from "./ModalText";
import React from "react";

const MENTION = "Ceci est les mention légale";
const CGU = "Texte pour les conditions générale d'utilisation";

const Footer = ({ detailsInfo }: { detailsInfo: DetailsInfo }) => {
  return (
    <footer className="bg-black shadow dark:bg-gray-900 m-0 p-2.5">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex">
          <div className="flex uk-flex-column uk-width-expand mb-4 sm:mb-0">
            <h2 className="text-2xl font-semibold whitespace-nowrap text-white">
              Cut Made & Folded
            </h2>
            <div className="mention sm:flex sm:items-center sm:justify-between">
              <div className="">
                <a
                  href="https://www.instagram.com/cutmadefolded"
                  className="uk-icon-button"
                  target="_blank"
                  uk-icon="icon:instagram"
                  style={{ height: 36, width: 36, color: "black" }}
                ></a>
              </div>
              <div>
                <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                  <li>
                    <ModalText
                      title="Mention L&eacute;gale"
                      text={detailsInfo.mention_legal}
                      targetId="target: #modal-mention"
                      idKey="modal-mention"
                    />
                  </li>
                  <li>
                    <ModalText
                      title="Condition G&eacute;n&eacute;rale D'utilisation"
                      text={detailsInfo.mention_cgu}
                      targetId="target: #model-cgu"
                      idKey="model-cgu"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400 text-white">
          © 2023{" "}
          <a href="#" className="hover:underline text-white font-extrabold">
            <strong> Cut Made & Folded™</strong>
          </a>
          . All Rights Reserved.
        </span>
      </div>

      <></>
    </footer>
  );
};

export default Footer;
