import { list } from "postcss";
import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const listCards = atom({
  key: "ListCard",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const aboutData = atom({
  key: "aboutData",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const filters = atom({
  key: "filter",
  default: "",
});

const filterList = selector({
  key: "FilterListCard",
  get: ({ get }) => {
    const list_cards: Theme[] = get(listCards);
    if (list_cards?.length > 0) {
      return list_cards.filter((x) => x.isActiveTheme == true);
    }
    return [];
  },
});

//Product Management
const productState = atom({
  key: "productState",
  default: [],
});

const addProduct = atom({
  key: "addProduct",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const allProduct = selector({
  key: "allProduct",
  get: ({ get }) => {
    const getAllCards: AddCards[] = get(addProduct);
    let amount = 0;
    getAllCards.map((element) => {
      amount = amount + element.amount;
    });
    return { shopping_cards: getAllCards, total_amount: amount };
  },
});

export {
  filterList,
  filters,
  listCards,
  productState,
  addProduct,
  allProduct,
  aboutData,
};
