import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { addProduct, allProduct } from "../recoilAtomSelector/atomSelector";
import CheckoutPay from "./commun/Checkout/CheckoutPay";
import { CardsCheckout } from "./commun";

const Checkout = () => {
  let chechout: Checkout = useRecoilValue(allProduct);
  const [product, setProduct] = useRecoilState(addProduct);

  const deleteCards = () => {
    setProduct([]);
  };

  return (
    <div className="p-2.5 md:p-10 mt-10">
      {chechout.shopping_cards.length > 0 ? (
        <div className="">
          <a href="/" className="uk-flex uk-flex-middle">
            <span uk-icon="icon: arrow-left; ratio:1.2"></span>
            <span className="uk-link-heading">Retour</span>
          </a>
          <h2 className="uk-flex uk-flex-center">Votre Panier</h2>
          <div className="uk-accordion-content">
            {chechout.shopping_cards.map((element, index) => {
              return <CardsCheckout key={index} card={element} />;
            })}
          </div>
          <button
            className="uk-button uk-button-text uk-margin-medium"
            type="button"
            onClick={deleteCards}
            uk-tooltip="title: Enlèver toutes les cartes du panier"
          >
            Supprimer mon panier
          </button>
          <div className="uk-flex uk-flex-right">
            <h3>Total à payer: {chechout.total_amount} &euro;</h3>
          </div>
          <hr />
          <div className="uk-accordion-content">
            {<CheckoutPay total_amount={chechout.total_amount} />}
          </div>
        </div>
      ) : (
        <div className="uk-flex uk-flex-middle uk-flex-column">
          <h2>Votre panier est vide.</h2>
          <p>
            Vous souhaitez chosir une carte. Cliquez <a href="/">ici</a>
          </p>
        </div>
      )}

      {/** To confirm */}

      <div id="modal-example" uk-modal="true">
        <div className="uk-modal-dialog uk-modal-body">
          <h2 className="uk-modal-title">Confirmation</h2>
          <p>Voulez-vous supprimer votre panier ?</p>
          <p className="uk-text-right">
            <button
              className="uk-button uk-button-text uk-modal-close uk-margin-small-right uk-margin-small-left"
              type="button"
            >
              Annuler
            </button>
            <button
              className="uk-button uk-button-danger  uk-margin-small-right uk-margin-small-left"
              type="button"
              onClick={() => console.log(true)}
            >
              Confirmer
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
