import React from "react";

const ModalText = ({ title, text, targetId, idKey }) => {
  return (
    <>
      {" "}
      <a
        uk-toggle={targetId}
        className="mr-4 hover:underline md:mr-6 text-white"
      >
        {title}
      </a>
      <div id={idKey} uk-modal="true">
        <div className="uk-modal-dialog uk-modal-body">
          <h2 className="uk-modal-title">{title}</h2>
          <div>{text}</div>
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
        </div>
      </div>
    </>
  );
};

export default ModalText;
