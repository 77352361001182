import { useForm } from "react-hook-form";
import React, { useRef, useState, useEffect, Suspense } from "react";
import emailjs from "@emailjs/browser";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../commun/Loading";
import Title from "../commun/Title";
import CheckoutPay from "../commun/Checkout/CheckoutPay";

const Contact = () => {
  const [disable, setDisable] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState,
    formState: { isSubmitSuccessful, errors },
    reset,
  } = useForm();
  const [disabledSunetworkbmitButton, setDisabledSunetworkbmitButton] =
    useState(false);

  const form = useRef();
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset((formValues) => ({
        ...formValues,
        lastName: "",
        firstName: "",
        user_email: "",
        message: "",
      }));
    }
  }, [reset]);
  const onSubmit = async (data, e) => {
    setDisabledSunetworkbmitButton(true);
    setDisable(true);
    // console.log(data);
    // console.log(form);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_KEY_ID
      )
      .then(
        (result) => {
          //console.log(result.text);
          toast.success("Votre email à été envoyé avec succès.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
          e.target.reset();
        },
        (error) => {
          //console.error(error.text);
          toast.error("Votre email n'a pas été envoyé.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        }
      )
      .finally(() => {
        setDisabledSunetworkbmitButton(false);
        setDisable(false);
      });
  };
  return (
    <div>
      <Title
        title={"Contact"}
        message={'"Nous sommes là pour vous écouter et répondre à vos besoins"'}
      />
      <div className="p-2.5 md:p-10 mt-10">
        {/* Same as */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          theme="light"
          transition={Bounce}
        />
        {/**
       * 
       * 
       * <div className="section">
        <p className="section_title"> Contact</p>
      </div>
       */}

        {/** */}
        <div className="container_form grid gap-4 xl:gap-6 mt-28 mb-10 grid-cols-1 grid-rows-2 xl:grid-cols-2 xl:grid-rows-1">
          {" "}
          <Suspense fallback={<Loading />}>
            <div className="map_container w-full">
              <div className="mapouter">
                <div className="gmap_canvas md:w-fit-content">
                  <iframe
                    height="535"
                    frameBorder="0"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d61386.58634408345!2d-61.26625254651065!3d15.9267873863436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sfr!4v1666523392742!5m2!1sen!2sfr"
                    scrolling="no"
                    loading="lazy"
                    className="w-full sm:w-full 2xl:w-5/6"
                    title="marie_galante"
                  ></iframe>
                </div>
              </div>
            </div>
          </Suspense>
          <div className="xl:p-3">
            <p className="text-base font-semibold text-black m-0">
              Informations:
            </p>
            <p className="italic m-0">
              * Pour toutes demandes, informations compl&eacute;mentaires,
              veuillez remplir le formulaire ci-dessous. Merci.
            </p>
            <div>
              <form
                ref={form}
                className="contact_section_form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="uk-inline field_form">
                  <span className="uk-form-icon" uk-icon="icon: user"></span>
                  <input
                    className="uk-input uk-width uk-margin-small"
                    type="text"
                    placeholder="Nom"
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName && <p>Votre nom est requis.</p>}
                </div>
                <div className="uk-inline field_form">
                  <span className="uk-form-icon" uk-icon="icon: user"></span>
                  <input
                    className="uk-input uk-width uk-margin-small"
                    type="text"
                    placeholder="Prenom"
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName && <p>Votre pr&eacute;nom est requis.</p>}
                </div>
                <div className="uk-inline field_form">
                  <span className="uk-form-icon" uk-icon="icon: mail"></span>
                  <input
                    className="uk-input uk-width uk-margin-small"
                    type="email"
                    placeholder="Adresse mail"
                    {...register("user_email", { required: true })}
                  />
                  {errors.user_email && <p>Votre adresse mail est requis.</p>}
                </div>
                <div className="uk-inline field_form uk-margin-small">
                  <textarea
                    className="uk-textarea uk-width uk-margin-small message_form"
                    rows={9}
                    placeholder="Message..."
                    {...register("message", { required: true })}
                    {...(errors.message && (
                      <p>Votre adresse mail est requis.</p>
                    ))}
                  ></textarea>
                </div>
                <input
                  disabled={disable}
                  className="uk-button uk-button-default  w-1/2 md:w-1/4"
                  value="Envoyer"
                  type="submit"
                />
              </form>
            </div>
          </div>
        </div>

        {/** */}

        {/** */}
      </div>
    </div>
  );
};

export default Contact;
