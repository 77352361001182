import {
  AddressElement,
  Elements,
  ExpressCheckoutElement,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import Stripe from "stripe";
import {
  addProduct,
  allProduct,
} from "../../../recoilAtomSelector/atomSelector";
import { useRecoilState, useRecoilValue } from "recoil";
import { redirect } from "react-router";

const CheckForm = ({ total_amount }: { total_amount: number }) => {
  const checkout = useRecoilValue(allProduct);
  let test = useRecoilValue(allProduct);
  const [product, setProduct] = useRecoilState(addProduct);
  const stripeClient = useStripe();
  const elements = useElements();
  const amount = checkout.total_amount;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }

    let info = {};
    let orderSumUp = [];
    checkout.shopping_cards.map((element, index) => {
      info[index] = JSON.stringify({
        idCard: element.idCard,
        name: element.name,
        amount: element.amount,
        qte: element.qte,
      });

      orderSumUp.push({
        idCard: element.idCard,
        name: element.name,
        amount: element.amount,
        qte: element.qte,
      });
    });

    const stripe_client = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

    const res = await stripe_client.paymentIntents
      .create({
        amount: amount * 100,
        currency: "EUR",
        receipt_email: process.env.REACT_APP_EMAIL,
        metadata: info,
      })
      .then((data) => {
        return data;
      });
    const { client_secret: clientSecret } = res;

    const { error } = await stripeClient.confirmPayment({
      elements,
      clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: `${window.location.origin}/?sucess=true`,
      },
    });
    if (error == null || error == undefined) {
      setTimeout(() => {
        redirect("/");
        setTimeout(() => setProduct([]), 500);
      }, 1000);
    } else {
      //console.log(error.message)
      alert(error.message);
    }
    //console.log(error);
  };

  return (
    <main>
      <form onSubmit={handleSubmit}>
        <AddressElement options={{ mode: "billing" }} />
        <LinkAuthenticationElement />
        <PaymentElement />

        <div className="pt-5">
          <button className="uk-button uk-button-default" type="submit">
            Payer
          </button>
        </div>
      </form>
    </main>
  );
};

const CheckoutPay = ({ total_amount }: { total_amount: number }) => {
  const [orderId, setOrderId] = useState("Element");
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const defaultOption: StripeElementsOptions = {
    mode: "payment",
    amount: total_amount * 100,
    currency: "eur",
  };

  return (
    <div className="p-2 md:p-5 mt-5">
      <Elements stripe={stripePromise} options={defaultOption}>
        <CheckForm total_amount={total_amount} />
      </Elements>
    </div>
  );
};

export default CheckoutPay;
