import "../css/style.scss";
import About from "./main_component/About";
import Contact from "./main_component/Contact";
import Home from "./main_component/Home";
import React, { Suspense, useEffect, useState } from "react";
import axios from "axios";
import Loading from "./commun/Loading";
import Navigation from "./commun/Navigation";
import Footer from "./commun/Footer";
import { FloatingButton } from "./commun";
import ButtonCheckout from "./commun/ButtonCheckout";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  aboutData,
  filterList,
  listCards,
} from "../recoilAtomSelector/atomSelector";
import ContactForm from "./main_component/ContactForm";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [ping, setPing] = useState(0);
  const [data, setData] = useState([]);
  const [network, setNetwork] = useState(window.navigator.onLine);
  const [cards, setCards] = useRecoilState(listCards);
  const [about, setAbout] = useRecoilState(aboutData);
  const details: DetailsInfo = {
    mention_cgu: "",
    mention_cgv: "",
    mention_legal: "",
  };
  const [mention, setMention] = useState(details);
  const cardsList = useRecoilValue(filterList);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL, {
        responseType: "json",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        const getData: Data = response.data.data[0];
        // console.log("Default element inside");
        //setData(getData.dataHome);
        const { dataHome: data_home } = getData;
        const { dataAbout: data_about } = getData;
        const { socialNetwork: social_network } = getData;
        const { detailsInfo: details_info } = getData;
        setCards(data_home);
        setAbout(data_about);
        setMention(details_info);
      })
      .catch((error) => {
        // console.error("Error: We have an issues... ");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading === true ? (
        <Suspense fallback={<Loading />}></Suspense>
      ) : cardsList?.length === 0 ? (
        <div className="text">
          <div className="logo">
            <h1 className="logo_name">CutMadeFolded </h1>
          </div>
          <div className="uk-flex uk-flex-center uk-margin-large ">
            <p className="uk-heading-small typewriter">
              {" "}
              Le site est en maintenance...
            </p>
          </div>
        </div>
      ) : (
        <div>
          <Navigation></Navigation>
          <div id="home">
            {/* <Home data={data.dataHome} />*/}
            <Home />
          </div>
          <div id="about">
            {/*<About data={data.dataAbout} /> */} <About />
          </div>

          <div id="contact">{/*<Contact data={data.socialNetwork} /> */}</div>
          <Contact />
          {/*<FloatingButton /> */}
          {/*<ButtonCheckout />*/}
          <div id="contact">
            <Footer detailsInfo={mention} />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
