import React, { Suspense, useState, useEffect } from "react";
import Loading from "../commun/Loading";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  filterList,
  filters,
  listCards,
} from "../../recoilAtomSelector/atomSelector";
import { CardsManagement } from "../commun";

const Home = () => {
  //const [defaultData, setDefaultData] = useState(data);

  const [listPicture, setListPicture] = useState([]);

  const [name, setName] = useState("all");

  const [defaultValue, setDefaultValue] = useState([]);

  const [defaultFilter, setDefaultFilter] = useRecoilState(filters);
  const [defaultListCards, setDefaultListCards] = useState([]);
  const getListCards = useRecoilValue(filterList);

  {
    /** other */
  }

  const handleAddCard = (e) => {
    //console.log("Add: ", e);
  };

  const value = (title) => {
    /*console.clear();
    console.log(title);
    if (title === "Tous") {
      allPicture();
    } else {
      let test = data.find((x) => x.title == title);
      setListPicture(test.list_images);
    }*/
  };

  const allPicture = () => {
    /* let listImages = [];
    data.map((element) => {
      listImages = listImages.concat(element.list_images);
    });
    setListPicture(listImages);*/
  };

  const handleSelect = (e) => {
    /* console.log(e);
    console.log(e.target.value); */
    setDefaultFilter(e.target.value);
  };
  /*
  useEffect(() => {
    /*console.log("Element", data);
    setDefaultListCards(data);
    let title = [];
    data.map((element) => {
      title.push(element.title);
    });
    title.unshift("Tous");
    setDefaultValue(title);
    allPicture();

    console.log(data);
    setDefaultListCards(data);
  }, []);*/

  return (
    <div className="p-2.5 md:p-10 mt-10">
      {/** Attention */}
      <h2 className="font-extrabold text-black text-4xl p-x-10 p-y-1">
        Cartes
      </h2>
      <figure className="">
        {" "}
        <blockquote className="mb-1">
          <p className="font-thin text-base mb-0 pb-0">
            "Une carte created by
            <strong className="font-bold"> Cut Made & Folded</strong> vaut mille
            mots. Il ne manque plus qu'a chosir parmi cette sélection."
          </p>
        </blockquote>
        <figcaption>
          <cite className="m-y-0 p-y-0 not-italic text-sm">
            {" "}
            L'équipe de Cut Made & Folded
          </cite>
        </figcaption>{" "}
      </figure>
      {/**  */}

      <CardsManagement />
      <div className="uk-flex uk-flex-center uk-text-italic uk-text-medium uk-text-center	">
        <strong className="">
          Vous voulez voir d'autre cartes? Rendez-vous sur le compte instagram
          de &nbsp;
          <a target="_blank" href="https://www.instagram.com/cutmadefolded">
            Cut Made & Folded
          </a>
        </strong>
      </div>
    </div>
  );
};

export default Home;
